import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = ["owner", "collaborators", "taskOwner"];

  static values = { url: String };

  connect() {
    this.initCollaboratorsSelect();
    this.taskOwnerTargets.forEach((targetElement) => {
      this.initTaskOwnerDropdown(targetElement);
      this.updateTaskOwnerDropdownOptions(targetElement);
    });
    this.setupComplete = true;

    this.ownerChangeListener = () => {
      this.updateCollaborators();
      this.taskOwnerTargets.forEach((targetElement) => {
        this.updateTaskOwnerDropdownOptions(targetElement);
      });
    };

    this.collaboratorsChangeListener = () => {
      this.taskOwnerTargets.forEach((targetElement) => {
        this.updateTaskOwnerDropdownOptions(targetElement);
      });
    };

    this.ownerTarget.addEventListener("change", this.ownerChangeListener);
    this.collaboratorsTarget.addEventListener(
      "change",
      this.collaboratorsChangeListener
    );
  }

  taskOwnerTargetConnected(targetElement) {
    if (this.setupComplete) {
      this.initTaskOwnerDropdown(targetElement);
      this.updateTaskOwnerDropdownOptions(targetElement);
    }
  }

  disconnect() {
    this.destroyTomSelects();
    this.ownerTarget.removeEventListener("change", this.ownerChangeListener);
    this.collaboratorsTarget.removeEventListener(
      "change",
      this.collaboratorsChangeListener
    );
  }

  destroyTomSelects() {
    if (this.collaboratorsMultiSelect) {
      this.collaboratorsMultiSelect.destroy();
    }
    this.taskOwnerTargets.forEach((targetElement) => {
      if (targetElement.tomselect) {
        targetElement.tomselect.destroy();
      }
    });
  }

  initCollaboratorsSelect() {
    const options = {
      placeholder: "Select collaborators",
      hidePlaceholder: true,
      preload: true,
      plugins: { remove_button: { title: "Remove" } },
      items: this.getSelectedCollaborators(),
      options: this.getOptionsExcludingOwner(),
    };
    this.collaboratorsMultiSelect = new TomSelect(
      this.collaboratorsTarget,
      options
    );
  }

  updateCollaborators() {
    this.collaboratorsMultiSelect.clear();
    this.collaboratorsMultiSelect.clearOptions();
    this.getOptionsExcludingOwner().forEach((option) =>
      this.collaboratorsMultiSelect.addOption(option)
    );
  }

  initTaskOwnerDropdown(targetElement) {
    if (!targetElement.tomselect) {
      new TomSelect(targetElement, {
        controlInput: null,
        maxItems: 1,
        create: false,
        allowEmptyOption: true,
        render: {
          option: (data, escape) =>
            `<div><span class="block">${escape(data.text)}</span></div>`,
          item: (data, escape) => `<div>${escape(data.text)}</div>`,
        },
      });
    }
  }

  getOptionsExcludingOwner() {
    return Array.from(this.collaboratorsTarget.options)
      .filter((option) => option.value !== this.ownerTarget.value)
      .map((option) => ({ value: option.value, text: option.text }));
  }

  getSelectedCollaborators() {
    return Array.from(this.collaboratorsTarget.selectedOptions).map(
      (option) => option.value
    );
  }

  updateTaskOwnerDropdownOptions(targetElement) {
    const selectedCollaborators = Array.from(
      this.collaboratorsTarget.selectedOptions
    ).map((option) => option.value);

    const taskOwnerOptions = Array.from(targetElement.options)
      .filter(
        (option) =>
          option.value === this.ownerTarget.value ||
          selectedCollaborators.includes(option.value) ||
          option.value == ""
      )
      .map((option) => ({ value: option.value, text: option.text }));

    const currentSelectedValue = targetElement.tomselect.getValue();
    if (
      !taskOwnerOptions.some((option) => option.value === currentSelectedValue)
    ) {
      targetElement.tomselect.clear();
      targetElement.tomselect.setValue("");
    }
    targetElement.tomselect.clearOptions();
    targetElement.tomselect.addOptions(taskOwnerOptions);
  }
}
